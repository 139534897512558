import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.gaurd';
import { LogoutGuard } from './core/auth/logout-gaurd';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './content/auth/login/login.module#LoginModule',
    canActivate:[LogoutGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: './content/auth/forgot-password/forgot-password.module#ForgotPasswordModule',
    canActivate:[LogoutGuard]
  },
  {
    path: 'forgot-password-confirmation',
    loadChildren: './content/auth/forgot-password-confirmation/forgot-password-confirmation.module#ForgotPasswordConfirmationModule',
    canActivate:[LogoutGuard]
  },
  {
    path: 'reset-password',
    loadChildren: './content/auth/reset-password/reset-password.module#ResetPasswordModule',
    canActivate:[LogoutGuard]
  },
  {
    path: 'pages',
    loadChildren: './content/pages/pages.module#PagesModule',
    canActivate:[AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

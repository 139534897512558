import { Injectable } from '@angular/core';
import { CommonHttpService } from 'src/app/core/interceptor/header-interceptor';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  limit = '&limit=';
  skip = '?skip='

  constructor(private commonHttpService: CommonHttpService) { }

  // Category's API
  categoryList(skip, limit, type) {
    return this.commonHttpService.get('category' + this.skip + skip + this.limit + limit + '&type=' + type);
  }
  categoryListWithoutType() {
    return this.commonHttpService.get('category');
  }
  editCategory(id, data) {
    return this.commonHttpService.put('category/' + id, data);
  }
  addCategory(data) {
    return this.commonHttpService.post('category/', data);
  }
  deleteCategory(id) {
    return this.commonHttpService.delete('category/' + id);
  }
  updateCategory(data) {
    return this.commonHttpService.post('image/updateCategory', data);
  }

  // Changes
  markFeatured(data) {
    return this.commonHttpService.post('category/markFeatured', data);
  }

  // Upload Images, List, Delete, Data
  uploadImages(data) {
    return this.commonHttpService.post('image', data);
  }
  imageList(id, skip, limit, sort, type) {
    return this.commonHttpService.get('image/list/' + id + this.skip + skip + this.limit + limit + '&sort=' + sort + '&type=' + type);
  }
  deleteImage(data) {
    return this.commonHttpService.post('image/delete', data);
  }
  imageData(id) {
    return this.commonHttpService.get('image/' + id);
  }

  // Notify Users
  notifyUsers(type: string) {
    const obj = {
      onlyNotification: type === 'true' ? true : ''
    };
    return this.commonHttpService.post('image/notifyUsers', obj);
  }

  // Tags API's
  getTags(skip, limit, keyword) {
    return this.commonHttpService.get('image/tags' + this.skip + skip + this.limit + limit + '&keyword=' + keyword);
  }
  addTag(id, data) {
    return this.commonHttpService.put('image/' + id, data);
  }
  search(skip, limit, sort, data) {
    return this.commonHttpService.post('image/search' + this.skip + skip + this.limit + limit + '&sort=' + sort, data);
  }

}

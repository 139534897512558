import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (localStorage.getItem('appId') === '2') {
  document.title = 'Pomifera Photo App Admin';
  const link = document.getElementById('favicon');
  link.setAttribute('href', 'assets/images/favicon.png');
} else if (localStorage.getItem('appId') === '3') {
  document.title = 'Farmasi Photo App Admin';
  const link = document.getElementById('favicon');
  link.setAttribute('href', 'assets/images/farmasi-logo.png');
} else if (localStorage.getItem('appId') === '4') {
  document.title = 'Boo Photo App Admin';
  const link = document.getElementById('favicon');
  link.setAttribute('href', 'assets/images/BOO-Photos_Logo.png');
}

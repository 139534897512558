import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements  CanActivate{
  constructor(private _route:Router){ }
  path: ActivatedRouteSnapshot[];  route: ActivatedRouteSnapshot;

  canActivate(){
    if(localStorage.getItem('flavour-factoryadmintoken'))
    { 
     this._route.navigate(['pages/dashboard']);
      return false;
    }
    else
    {
    return true;
    }
  }

  
}
